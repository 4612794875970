import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Dexie from 'dexie';

@Injectable()

export class DataBaseService{
  iDb:Dexie;
  tableMotivosPendencia: Dexie.Table<any> = null;
  tableEmpresas: Dexie.Table<any> = null;
  tablePlanosFisicos: Dexie.Table<any> = null;
  tablePlanosEmpresariais: Dexie.Table<any> = null;
  tablePlanosServidores: Dexie.Table<any> = null;
  tableSubContratos: Dexie.Table<any> = null;
  tableCredenciados: Dexie.Table<any> = null;
  tableCidades: Dexie.Table<any> = null;
  tableEspecialidades: Dexie.Table<any> = null;
  tableAgenda: Dexie.Table<any> = null;
  tableVendedores: Dexie.Table<any> = null;
  emails:any = [];
  cidades:any = [];
  vendedores:any = [];
  especialidades:any = [];
  subContratos:any = [];
  empresas:any = [];
  motivosPendencia:any = [];
  credenciados:any = [];
  planosFisicos:any = [];
  planosServidores:any = [];
  planosEmpresariais:any = [];
  agendaVendedor:any = null;
  corVendedor:any = "";
  comissaoPlanoFisico:number = 0;
  comissaoPlanoServidor:number = 0;
  urlDataBase:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/http/dbhookweb";
  urlSite:any = "https://app.vivamax.com.br";
  urlUpload:any = "https://vendas.vivamax.com.br"
  apiTopMed:any = "https://api.nextplus.com.br/lhapi";
  apiVindi:any = "https://app.vindi.com.br/api/v1/";
  apiCreateVindi:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/createVindi";
  apiAssinaturaVindi:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/assinaturaVindi";
  apiSorte:any = this.urlDataBase+'?coll=sorte';
  apiScripts:any = this.urlDataBase+'?coll=scripts';
  apiLeads:any = this.urlDataBase+'?coll=leads';
  apiUsuarios:any = this.urlDataBase+'?coll=usuarios';
  apiAssociados:any = this.urlDataBase+'?coll=associados';
  apiPlanos:any = this.urlDataBase+'?coll=planos';
  apiLogs:any = this.urlDataBase+'?coll=logs';
  apiSubContratos:any = this.urlDataBase+'?coll=subcontratos';
  apiConfiguracoes:any = this.urlDataBase+'?coll=configuracoes';
  apiConfigs:any = this.urlDataBase+'?coll=configuracoes';
  apiCredenciados:any = this.urlDataBase+'?coll=credenciados';
  apiClientesFunil:any = this.urlDataBase+'?coll=clientesFunil';
  apiEmpresas:any = this.urlDataBase+'?coll=empresas';
  apiVendas:any = this.urlDataBase+'?coll=vendas';
  carregando:boolean = false;
  userTop:any = "";
  senhaTop:any = "";
  sandboxTop:any = "";
  filaEspera:number = 0;

  constructor(public http:HttpClient){
    window.addEventListener("load", () => {
        if(navigator.onLine){
          this.init();
        }else{
          this.initOff();
        }
     });
  }

  init(){
    this.getVendedoresAssessores().subscribe((vender:any) => {
      this.vendedores = vender;
        this.getEmpresas().subscribe((dataEmp:any) => {
          this.empresas = [];
          this.empresas = dataEmp;
            this.getConfigs().subscribe((x:any) => {
              this.comissaoPlanoFisico = x[1].comissaoPlanoFisico;
              this.comissaoPlanoServidor = x[1].comissaoPlanoServidor;
              this.motivosPendencia = x[11].motivosPendencia;
              this.emails = x[23].emails;
              this.cidades = x[8].cidades;
              this.especialidades = x[7].especialidades;
              this.getSubContratos().subscribe((data:any) => {
                this.subContratos = data;
                this.getVendedorById(localStorage.getItem('idUserVendas')).subscribe((dataVendedor:any) => {
                  localStorage.setItem('permissoes',JSON.stringify(dataVendedor.permissoes));
                  this.agendaVendedor = dataVendedor.agenda;
                  this.corVendedor = dataVendedor.color;
                  this.planosFisicos = [];
                  this.planosServidores = [];
                  this.planosEmpresariais = [];
                  this.getPlanos().subscribe((p:any) => {
                    p.forEach((item) => {
                    if(item.tipo == 'PLANO FISICO'){
                      this.planosFisicos.push(item);
                      this.planosFisicos.sort(function(a,b){
                        if(a.nome < b.nome)return -1;
                        if(a.nome > b.nome)return 1;
                      })
                     }
                    if(item.tipo == 'PLANO EMPRESARIAL'){
                      this.planosEmpresariais.push(item);
                      this.planosEmpresariais.sort(function(a,b){
                        if(a.nome < b.nome)return -1;
                        if(a.nome > b.nome)return 1;
                      })
                    }
                    if(item.tipo == 'PLANO SERVIDOR'){
                      this.planosServidores.push(item);
                      this.planosServidores.sort(function(a,b){
                        if(a.nome < b.nome)return -1;
                        if(a.nome > b.nome)return 1;
                      })
                    }
                  });
                  this.getCredenciados().subscribe((data:any) => {
                    this.credenciados = data;
                    new Dexie("dados").open().then(function(db){
                      db.table('motivosPendencia').clear().then(() => {
                        let ctrlMo = 1;
                        this.motivosPendencia.forEach((motivo) => {
                          db.table('motivosPendencia').add({nome:motivo.nome});
                          if(ctrlMo == this.motivosPendencia.length){
                            this.tableMotivosPendencia = this.motivosPendencia;
                          }else{
                            ctrlMo++
                          }
                        });
                    }).catch((e) => console.log(e));
                    db.table('planosFisicos').clear().then(() => {
                      let ctrlF = 1;
                      this.planosFisicos.forEach((fisico) => {
                        db.table('planosFisicos').add(fisico);
                        if(ctrlF == this.planosFisicos.length){
                          this.tablePlanosFisicos = this.planosFisicos;
                        }else{
                          ctrlF++
                        }
                      });
                      }).catch((e) => console.log(e));
                      db.table('planosEmpresariais').clear().then(() => {
                        let ctrlF = 1;
                        this.planosFisicos.forEach((emp) => {
                          db.table('planosEmpresariais').add({nome:emp.nome});
                          if(ctrlF == this.planosEmpresariais.length){
                            this.tablePlanosEmpresariais = this.planosEmpresariais;
                          }else{
                            ctrlF++
                          }
                        });
                      }).catch((e) => console.log(e));
                      db.table('planosServidores').clear().then(() => {
                        let ctrlS = 1;
                        this.planosServidores.forEach((servidores) => {
                          db.table('planosServidores').add(servidores);
                          if(ctrlS == this.planosServidores.length){
                            this.tablePlanosServidores = this.planosServidores;
                          }else{
                            ctrlS++
                          }
                        });
                      }).catch((e) => console.log(e));
                        db.table('subContratos').clear().then(() => {
                          let ctrlSub = 1;
                          this.subContratos.forEach((sub) => {
                            db.table('subContratos').add(sub);
                            if(ctrlSub == this.subContratos.length){
                              this.tableSubContratos = this.subContratos;
                            }else{
                              ctrlSub++
                            }
                          });
                      }).catch((e) => console.log(e));
                      db.table('credenciados').clear().then(() => {
                        db.table('credenciados').add(this.credenciados)
                        this.tableCredenciados = this.credenciados;
                      }).catch((e) => console.log(e));
                      db.table('agenda').clear().then(() => {
                        db.table('agenda').add(this.agendaVendedor)
                        this.tableAgenda = this.agendaVendedor;
                      }).catch((e) => console.log(e));
                      db.table('cidades').clear().then(() => {
                        let ctrlCid = 1;
                        this.cidades.forEach((cid) => {
                      db.table('cidades').add({cidade:cid.cidade});
                      if(ctrlCid == this.cidades.length){
                        this.tableCidades = this.cidades;
                      }else{
                        ctrlCid++
                      }
                    });
                  }).catch((e) => console.log(e));
                    db.table('especialidades').clear().then(() => {
                      let ctrlEsp = 1;
                      this.especialidades.forEach((esp) => {
                        db.table('especialidades').add({nome:esp.nome});
                        if(ctrlEsp == this.especialidades.length){
                          this.tableEspecialidades = this.especialidades;
                        }else{
                          ctrlEsp++
                        }
                      });
                    }).catch((e) => console.log(e));
                    db.table('empresas').clear().then(() => {
                      let ctrlEmpresas = 1;
                      this.empresas.forEach((emp) => {
                        db.table('empresas').add(emp);
                        if(ctrlEmpresas == this.empresas.length){
                          this.tableEmpresas = this.empresas;
                        }else{
                          ctrlEmpresas++
                        }
                      });
                    }).catch((e) => console.log(e));
                    db.table('vendedores').clear().then(() => {
                      let ctrlVends = 1;
                      this.vendedores.forEach((emp) => {
                        db.table('vendedores').add(emp);
                        if(ctrlVends == this.vendedores.length){
                          this.tableVendedores = this.vendedores;
                        }else{
                          ctrlVends++
                        }
                      });
                    }).catch((e) => console.log(e));
                    this.verificaFilaEspera();
                  }.bind(this)).catch("NoSuchDatabaseError", function(e){
                    this.iniciarIndexedDb();
                  }.bind(this)).then(() => {
                    //void
                  });  
                });  
              });
            });
          });
         });
      });
  });
  }

  initOff(){
    new Dexie("dados").open().then(function(db){
      db.table('motivosPendencia').toArray().then((result:any) => {
        this.tableMotivosPendencia = result;
        this.motivosPendencia = result;
      }).catch((e) => {
        console.log(e)
      })
     db.table('subContratos').toArray().then((result:any) => {
      this.tableSubContratos = result;
      this.subContratos = result;
    }).catch((e) => {
      console.log(e)
    })
     db.table('planosFisicos').toArray().then((result:any) => {
       this.tablePlanosFisicos = result;
       this.planosFisicos = result;
     }).catch((e) => {
       console.log(e)
     })
     db.table('planosEmpresariais').toArray().then((result:any) => {
      this.tablePlanosEmpresariais = result;
      this.planosEmpresariais = result;
    }).catch((e) => {
      console.log(e)
    })
     db.table('planosServidores').toArray().then((re:any) => {
       this.tablePlanosServidores = re;
       this.planosServidores = re;
     }).catch((e) => {
       console.log(e)
     })
     db.table('credenciados').toArray().then((result:any) => {
      this.tableCredenciados = result;
      this.credenciados = result;
      }).catch((e) => {
        console.log(e)
      })
      db.table('agenda').toArray().then((result:any) => {
        this.tableAgenda = result;
        this.agenda = result;
        }).catch((e) => {
          console.log(e)
        })
      db.table('cidades').toArray().then((result:any) => {
        this.tableCidades = result;
        this.cidades = result;
        }).catch((e) => {
          console.log(e)
        })
        db.table('empresas').toArray().then((result:any) => {
          this.tableEmpresas = result;
          this.empresas = result;
          }).catch((e) => {
            console.log(e)
          })
      db.table('vendedores').toArray().then((result:any) => {
        this.tableVendedores = result;
        this.vendedores = result;
        }).catch((e) => {
          console.log(e)
        })
      db.table('especialidades').toArray().then((result:any) => {
        this.tableEspecialidades = result;
        this.especialidades = result;
        }).catch((e) => {
          console.log(e)
        })
   }.bind(this)).catch("NoSuchDatabaseError", function(e){
     alert("Não há Banco de Dados Sincronizado, por favor Conecte na Internet para Sincronizar os Dados!")
   }.bind(this)).then(() => {
     //void
   });
  }

  async iniciarIndexedDb(){
      this.iDb = new Dexie('dados');
      this.iDb.version(1).stores({
        motivosPendencia:"++id",
        subContratos:"++id",
        planosFisicos:"++id",
        planosEmpresariais:"++id",
        planosServidores:"++id",
        associados:"++id",
        credenciados:"++id",
        cidades:"++id",
        especialidades:"++id",
        empresas:"++id",
        vendedores:"++id",
        agenda:"++id"
      });
        //VENDEDORES
        this.iDb.table('vendedores').add(this.vendedores);
       //AGENDA
       this.iDb.table('agenda').add(this.agendaVendedor);
       //CREDENCIADOS
        this.iDb.table('credenciados').add(this.credenciados);
        //CIDADES
        await this.cidades.forEach((cid) => {
          this.iDb.table('cidades').add({nome:cid.cidade});
        });
        //ESPECIALIDADES
        await this.especialidades.forEach((esp) => {
          this.iDb.table('especialidades').add({nome:esp.nome});
        });
       //SUBCONTRATOS
       await this.subContratos.forEach((sub) => {
        this.iDb.table('subContratos').add({nome:sub.nome});
      });
       //MOTIVOS PENDENCIA
       await this.motivosPendencia.forEach((motivo) => {
        this.iDb.table('motivosPendencia').add({nome:motivo.nome});
      });
      this.tableMotivosPendencia = this.motivosPendencia;
       //EMPRESAS
       await this.empresas.forEach((emp) => {
        this.iDb.table('empresas').add(emp);
      });
      this.tablePlanosFisicos = this.planosFisicos;
      //PLANOS FISICOS
      await this.planosFisicos.forEach((fisico) => {
        this.iDb.table('planosFisicos').add({nome:fisico.nome});
      });
      this.tablePlanosFisicos = this.planosFisicos;
       //PLANOS EMPRESARIAIS
       await this.planosEmpresariais.forEach((emp) => {
        this.iDb.table('planosEmpresariais').add({nome:emp.nome});
      });
      this.tablePlanosEmpresariais = this.planosEmpresariais;
      //PLANOS SERVIDORES
      await this.planosServidores.forEach((servidores) => {
        this.iDb.table('planosServidores').add({nome:servidores.nome});
      });
      this.tablePlanosServidores = this.planosServidores;
  }

  
  checkEmail(email:any):boolean{
    if (email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
      if (emailPattern.test(email)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  
  checkCPF(c:any):boolean{
    if(c){
      let cpf:any = c.replace(/[.\-]/g, '')
        if(cpf && cpf.length >= 11){
          if(!cpf || cpf.length != 11 || cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" || cpf == "88888888888" || cpf == "99999999999"){
              return false;
          }
          let soma:number = 0;
          let resto:number = 0;
          for(let i = 1; i <= 9; i++){
            soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
          }
          resto = (soma * 10) % 11;
          if((resto == 10) || (resto == 11)){
            resto = 0;
          }  
          if(resto != parseInt(cpf.substring(9, 10))){
            return false;
          }else{
            soma = 0;
          }
          for(let i = 1; i <= 10; i++){
            soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
          }
          resto = (soma * 10) % 11;
          if((resto == 10) || (resto == 11)){
            resto = 0;
          }
          if(resto != parseInt(cpf.substring(10, 11))){
            return false;
          }
        return true;
      }else{
        return false;
      }
    }
  }

  getSorteLivre(){
    return this.http.get(this.apiSorte+'&method=GET&filtro={"usado":false}');
  }

  patchSorte(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiSorte+'&method=PATCH&id='+id+'&body='+body)
  }

  getConfigs(){
    return this.http.get(this.apiConfiguracoes+'&method=GET&sort={"_id":1}');
  }
  
  getLoginByUsuario(mail:any, pwd:any){
      return this.http.get(this.apiUsuarios+'&method=GET&filtro={"email":"'+mail+'","pwd":"'+pwd+'"}');
  }

  getAllUsuarios(){
   return this.http.get(this.apiUsuarios+'&method=GET');
  }
  
  getAllUsuariosAtivos(){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"situacao":{"$not":{"$regex":"DEMITIDO","$options":"i"}}}');
  }

  getAssociados(){
    return this.http.get(this.apiAssociados+'&method=GET');
  }

  getAssociadosPendentesByVendedor(vendedor:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"situacao":"PENDENTE","vendedor":"'+vendedor+'"}');
  }

  getAssociadosCanceladosByVendedor(dtInicio:any,dtFim:any,vendedor:any,motivo:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataCancelamento":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"situacao":"CANCELADO","vendedor":"'+vendedor+'","motivoCancelamento":{"$regex":"'+motivo+'"}}');
  }

  getAssociadosCanceladosTodosVendedor(dtInicio:any,dtFim:any,motivo:any,tipo:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={'+tipo+'"dataCancelamento":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"situacao":"CANCELADO","motivoCancelamento":{"$regex":"'+motivo+'"}}');
  }

  getAssociadosByVendedor(vendedor:any){
    let date = new Date();
    let fDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];;
    let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];;
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataAdesao":{"$gte":"'+fDay+'","$lte":"'+lDay+'"},"situacao":"ATIVO","vendedor":"'+vendedor+'"}');
  }

  getAssociadosById(id:any){
    return this.http.get(this.apiAssociados+'&method=GET&id='+id);
  }
  
  getAssociadosMatricula(key:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"matricula":'+key+'}');
  }

  getCanceladoInadimplenteCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"cpf":"'+cpf+'","situacao":"CANCELADO","motivoCancelamento":"INADIMPLÊNCIA"}');
  }

  getAssociadosCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"cpf":"'+cpf+'"}');
  }

  getAssociadosCpfPendenteByVendedor(cpf:any,vendedor:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"cpf":"'+cpf+'","situacao":"PENDENTE","vendedor":"'+vendedor+'"}');
  }

  getScripts(){
    return this.http.get(this.apiScripts+'&method=GET&sort={"ordem":1}');
  }

  cadastroScript(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiScripts+'&method=POST&body='+body);
  }

  patchScript(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiScripts+'&method=PATCH&id='+id+'&body='+body);
  }

  deleteScript(id:any){
    return this.http.get(this.apiScripts+'&method=DELETE&id='+id);
  }

  cadastroProposta(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=POST&body='+body);
  }
  
  getUsuariosTipo(tipo:any){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"tipo":"'+tipo+'"}');
  }

  getUsuariosByEmail(email:any){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"email":"'+email+'"}');
  }

  newHistorico(id:any,dados:any){
    let body = JSON.stringify({"historicos":dados});
    return this.http.get(this.apiAssociados+'&method=POSTINSIDE&id='+id+'&body='+body);
  }

  enviarChatFunil(id:any,idFunil:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiUsuarios+'&method=POSTCHAT&id='+id+'&idFunil='+idFunil+'&body='+body);
  }

  patchPrioridadeChat(id:any,idFunil:any,indice:any,prioridade:any){
    return this.http.get(this.apiUsuarios+'&method=CHATPRIORIDADE&id='+id+'&idFunil='+idFunil+'&indice='+indice+'&prioridade='+JSON.stringify(prioridade));
  }

  enviarAnexoChat(imagedata:any){
    let url = this.urlUpload+'/uploadChat.php';
    return this.http.post(url, imagedata);
  }

  postEvento(dados:any){
    let body = JSON.stringify({"agenda":dados});
    return this.http.get(this.apiUsuarios+'&method=POSTINSIDE&id='+dados.idUser+'&body='+body);
  }

  postTarefa(dados:any){
    let body = JSON.stringify({"tarefas":dados});
    return this.http.get(this.apiUsuarios+'&method=POSTINSIDE&id='+dados.idResponsavel+'&body='+body);
  }

  patchEvento(idUser:any,idAgenda:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiUsuarios+'&method=PATCHAGENDAVENDEDOR&id='+idUser+'&idAgenda='+idAgenda+'&body='+body);
  }

  deleteEvento(idUser:any,idAgenda:any){
    return this.http.get(this.apiUsuarios+'&method=DELAGENDAVENDEDOR&id='+idUser+'&idAgenda='+idAgenda);
  }
  
  getAssociadosTitular(titular:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"nome":{"$regex":"'+titular+'","$options":"i"}}');
  }

  getAssociadosTitularPendentesByVendedor(titular:any,vendedor:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"nome":{"$regex":"'+titular+'","$options":"i"},"situacao":"PENDENTE","vendedor":"'+vendedor+'"}');
  }

  getAssociadosTitularMesByVendedor(titular:any,vendedor:any){
    let date = new Date();
    let fDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];;
    let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];;
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataAdesao":{"$gte":"'+fDay+'","$lte":"'+lDay+'"},"nome":{"$regex":"'+titular+'","$options":"i"},"situacao":"ATIVO","vendedor":"'+vendedor+'"}');
  }

  getAssociadosMatriculaPendentesByVendedor(matricula:any,vendedor:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"matricula":'+matricula+',"situacao":"PENDENTE","vendedor":"'+vendedor+'"}');
  }

  getAssociadosMatriculaMesByVendedor(matricula:any,vendedor:any){
    let date = new Date();
    let fDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];;
    let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];;
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataAdesao":{"$gte":"'+fDay+'","$lte":"'+lDay+'"},"matricula":'+matricula+',"situacao":"ATIVO","vendedor":"'+vendedor+'"}');
  }

  getAssociadosByTipoPendentesByVendedor(tipo:any,vendedor:any){
    let query = "";
    if(tipo == "PLANO FISICO"){
      query = '"planoFisicoCheck":true'
    }
    if(tipo == "PLANO EMPRESARIAL"){
      query = '"planoEmpresarialCheck":true'
    }
    if(tipo == "PLANO SERVIDOR"){
      query = '"planoFisicoCheck":false,"$or":[{"planoEmpresarialCheck":false,"planoEmpresarialCheck":null}]';
    }
    return this.http.get(this.apiAssociados+'&method=GET&filtro={'+query+',"situacao":"PENDENTE","vendedor":"'+vendedor+'"}');
  }

  getAssociadosByTipoByVendedor(tipo:any,vendedor:any){
    let date = new Date();
    let fDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];;
    let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];;
    let query = "";
    if(tipo == "PLANO FISICO"){
      query = '"planoFisicoCheck":true'
    }
    if(tipo == "PLANO EMPRESARIAL"){
      query = '"planoEmpresarialCheck":true'
    }
    if(tipo == "PLANO SERVIDOR"){
      query = '"planoFisicoCheck":false,"$or":[{"planoEmpresarialCheck":false,"planoEmpresarialCheck":null}]';
    }
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataAdesao":{"$gte":"'+fDay+'","$lte":"'+lDay+'"},'+query+',"situacao":"ATIVO","vendedor":"'+vendedor+'"}');
  }

  
  getAssociadosByEmpresaPendentesByVendedor(empresa:any,vendedor:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"situacao":"PENDENTE","vendedor":"'+vendedor+'","empresa":"'+empresa+'"}');
  }

  getAssociadosByEmpresaByVendedor(empresa:any,vendedor:any){
    let date = new Date();
    let fDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];;
    let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];;
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataAdesao":{"$gte":"'+fDay+'","$lte":"'+lDay+'"},"situacao":"ATIVO","vendedor":"'+vendedor+'","empresa":"'+empresa+'"}');
  }

  getAssociadosBeneficiario(nomeBeneficiario:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentes.nome":{"$regex":"'+nomeBeneficiario+'","$options":"i"}}');
  }

   getAssociadosBeneficiarioExtra(nomeBeneficiario:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentesExtra.nome":{"$regex":"'+nomeBeneficiario+'","$options":"i"}}');
  }

  getAssociadosBeneficiarioPendenteByVendedor(nomeBeneficiario:any,vendedor:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentes.nome":{"$regex":"'+nomeBeneficiario+'","$options":"i"},"situacao":"PENDENTE","vendedor":"'+vendedor+'"}');
  }

  getAssociadosBeneficiarioPendenteExtraByVendedor(nomeBeneficiario:any,vendedor:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentesExtra.nome":{"$regex":"'+nomeBeneficiario+'","$options":"i"},"situacao":"PENDENTE","vendedor":"'+vendedor+'"}');
  }

  getAssociadosBeneficiarioByVendedor(nomeBeneficiario:any,vendedor:any){
    let date = new Date();
    let fDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];;
    let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];;
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataAdesao":{"$gte":"'+fDay+'","$lte":"'+lDay+'"},"dependentes.nome":{"$regex":"'+nomeBeneficiario+'","$options":"i"},"situacao":"ATIVO","vendedor":"'+vendedor+'"}');
  }

  getAssociadosBeneficiarioExtraByVendedor(nomeBeneficiario:any,vendedor:any){
    let date = new Date();
    let fDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];;
    let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];;
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataAdesao":{"$gte":"'+fDay+'","$lte":"'+lDay+'"},"dependentesExtra.nome":{"$regex":"'+nomeBeneficiario+'","$options":"i"},"situacao":"ATIVO","vendedor":"'+vendedor+'"}');
  }

  getCep(cep:any){
    return this.http.get('https://viacep.com.br/ws/'+cep+'/json/');
  }

  patchAssociado(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=PATCH&id='+id+'&body='+body);
  }

  patchDependentes(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=PATCH&id='+id+'&body='+body);
  }

  getVendedores(){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"tipo":"VENDEDOR"}&sort={"nome":1}');
  }
  
  getVendedoresAssessores(){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"$or":[{"tipo":"VENDEDOR"},{"tipo":"ASSESSOR COMERCIAL"}]}&sort={"nome":1}');
  }

  getEquipeVendas(){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"colaboradorComercial":true}&sort={"nome":1}');
  }

  getVendedorById(id:any){
    return this.http.get(this.apiUsuarios+'&method=GET&id='+id);
  }

  postLogs(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiLogs+'&method=POST&body='+body);
  }
   
  enviarDocAssociado(id:any,imagedata:any){
    let url = this.urlSite+'/upload-doc-associado.php?id='+id;
    return this.http.post(url, imagedata);
  }

  removeDoc(fullUrl){
    let path = fullUrl.split('br/')[1];
    let url = this.urlSite+'/delete-doc.php?path='+path;
    return this.http.post(url,{});
  }

  removeDocAssociadoDB(id:any, url:any){
    return this.http.get(this.apiAssociados+'&method=DELETEDOC&id='+id+'&url='+url);
  }

   getEmpresas(){
    return this.http.get(this.apiEmpresas+'&method=GET&sort={"fantasiaEmpresa":1}')
  }

  getAssociadosVivamaxMatricula(key:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"VIVA MAX","matricula":'+key+'}&sort={"nome":1}');
  }

  getAssociadosVivamaxCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"VIVA MAX","cpf":"'+cpf+'"}&sort={"nome":1}');
  }

  getAssociadosConectaMatricula(key:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA","matricula":'+key+'}&sort={"nome":1}');
  }

  getAssociadosConectaCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA","cpf":"'+cpf+'"}&sort={"nome":1}');
  }
  
  getAssociadosVivamax(){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"VIVA MAX"}');
  }

  getAssociadosConecta(){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA"}');
  }

  getAssociadosConectaTitular(titular:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA","nome":{"$regex":"'+titular+'","$options":"i"}}');
  }

  getAssociadosConectaBeneficiario(nomeBeneficiario:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA","dependentes.nome":{"$regex":"'+nomeBeneficiario+'","$options":"i"}}');
  } 

  enviarDocCancelamento(id:any,imagedata:any){
    let url = this.urlSite+'/upload-doc-cancelamento.php?id='+id;
    return this.http.post(url, imagedata);
  }

  getPlanos(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"planoVendas":true}');
  }

   getPlanosConecta(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"empresaOrigem":"CONECTA","planoVendas":true}&sort={"tipo":-1,"nome":1}');
  }

  getPlanosVivamax(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"empresaOrigem":"VIVA MAX","planoVendas":true}&sort={"tipo":-1,"nome":1}');
  }

  getPlanosSeguros(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"empresaOrigem":"SEGURO","planoVendas":true}&sort={"tipo":-1,"nome":1}');
  }

  getPlanosById(id:any){
    return this.http.get(this.apiPlanos+'&method=GET&id='+id);
  }

  getPlanosByTipoByNome(tipo,nome){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"tipo":"'+tipo+'","nome":"'+nome+'"}');
  } 
  
  getSubContratos(){
    return this.http.get(this.apiSubContratos+'&method=GET');
  }

  getSubContratosByNome(nome){
    return this.http.get(this.apiSubContratos+'&method=GET&filtro={"nome":"'+nome+'"}');
  }

  patchMatricula(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiConfiguracoes+'&method=PATCH&id='+id+'&body='+body);
  }

  getTags(){
    return this.http.get(this.apiConfigs+'&method=GET&id=621e7d804682a87bc82c137e');
  }

  getNomesNegociacao(){
    return this.http.get(this.apiConfigs+'&method=GET&id=621ff28a145e0913004e3723');
  }

  getCredenciados(){
    return this.http.get(this.apiCredenciados+'&method=GET&filtro={"situacao":"ATIVO"}');
  }

  getClientesFunil(){
    return this.http.get(this.apiClientesFunil+'&method=GET&sort={"nome":1}');
  }

  getVendedoresByCoordenador(coordenador:any){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"coordenadorResponsavel":"'+coordenador+'"}');
  }

  getClientesFunilByIdVendedor(id:any){
    return this.http.get(this.apiClientesFunil+'&method=GET&filtro={"idVendedor":"'+id+'"}&sort{"nome":1}');
  }

  getAssociadosByComissao(dataInicioPlano:any,dataFimPlano:any,vendedorPlano:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"situacao":"ATIVO","data":{"$gte":"'+dataInicioPlano+'","$lte":"'+dataFimPlano+'"},"vendedor":{"$regex":"'+vendedorPlano+'","$options":"i"}}');
  }

  postClienteFunil(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiClientesFunil+'&method=POST&body='+body);
  }

  patchClienteFunil(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiClientesFunil+'&method=PATCH&id='+id+'&body='+body);
  }

  postFunil(idVendedor:any,dados:any){
    let body = JSON.stringify({"funil":dados});
    return this.http.get(this.apiUsuarios+'&method=POSTINSIDE&id='+idVendedor+'&body='+body)
 }

  patchFunil(idUsuario:any,idFunil:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiUsuarios+'&method=PATCHFUNIL&id='+idUsuario+'&idFunil='+idFunil+'&body='+body)
  }

  ganharFunil(idUsuario:any,idFunil:any,dtTermino:any){
    return this.http.get(this.apiUsuarios+'&method=GANHARFUNIL&id='+idUsuario+'&idFunil='+idFunil+'&dtTermino='+JSON.stringify(dtTermino))
  }

  perderFunil(idUsuario:any,idFunil:any,dtTermino:any,motivoPerda:any){
    return this.http.get(this.apiUsuarios+'&method=PERDERFUNIL&id='+idUsuario+'&idFunil='+idFunil+'&dtTermino='+JSON.stringify(dtTermino)+'&motivoPerda='+JSON.stringify(motivoPerda))
  }

  patchEtapaFunil(idUsuario:any,idFunil:any,etapa:any){
    return this.http.get(this.apiUsuarios+'&method=ETAPAFUNIL&id='+idUsuario+'&idFunil='+idFunil+'&etapa='+JSON.stringify(etapa))
  }

  retomarFunil(idUsuario:any,idFunil:any){
    return this.http.get(this.apiUsuarios+'&method=RETOMARFUNIL&id='+idUsuario+'&idFunil='+idFunil)
  }
  
  getVendedoresAtivos(){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"tipo":"VENDEDOR","situacao":{"$not":{"$regex":"DEMITIDO","$options":"i"}}}&sort={"nome":1}');
  }

  forceDelete(){
    Dexie.getDatabaseNames((names) => {
      console.log('database names: ', names);
      names.forEach(function (name) {
        var db = new Dexie(name);
        db.delete().then(function() {
            console.log('Database successfully deleted: ', name);
        }).catch(function (err) {
            console.error('Could not delete database: ', name, err);
        }).finally(function() {
            console.log('Done. Now executing callback if passed.');
            location.reload();
        });
      });
    });
  }

  getEmailLeads(email:any){
    return this.http.get(this.apiLeads+'&method=GET&filtro={"email":"'+email+'"}')
  }

  postEmailLead(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiLeads+'&method=POST&body='+body);
  }

  patchEmailLead(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiLeads+'&method=PATCH&id='+id+'&body='+body);
  }
  
  getTokenTopMed(){
    let details:any = {
      username: this.userTop,
      password: this.senhaTop,
      grant_type: 'password'
    }
    let body:any = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      body.push(encodedKey + "=" + encodedValue);
    }
    body = body.join("&");
    return this.http.post(this.apiTopMed+'/V1/api/token',body);
  }

  postTelemedicina(dados:any,token:any){
    let header = {
      headers: new HttpHeaders().set('Authorization',  `Bearer ${token}`)
    }
    return this.http.post(this.apiTopMed+'/V2/api/CadastroPessoa',dados,header);
  }
  
  verificaFilaEspera(){
    new Dexie("dados").open().then(function(database){
      database.table('associados').toArray().then((result:any) => {
        if(result){
          this.filaEspera = result.length;
        }else{
          this.filaEspera = 0;
        }
      })
    }.bind(this)).catch((e) => {
      console.log(e)
   })
  }

postClienteVindi(dados:any){
   return this.http.get(this.apiCreateVindi+'?corpo='+JSON.stringify(dados));
  }

postAssinaturaVindi(dados:any){
    return this.http.get(this.apiAssinaturaVindi+'?corpo='+JSON.stringify(dados));
  }
  
getUsuarioById(id:any){
  return this.http.get(this.apiUsuarios+'&method=GET&id='+id);
}

getAssociadoByCpfEmpresa(array:any,cpf:any,empresa:any){
  return this.http.get(this.apiAssociados+'&method=GET&project={'+array+'}&filtro={"cpf":"'+cpf+'","empresa":"'+empresa+'","situacao":{"$not":{"$regex":"CANCELADO"}}}&sort={"empresa":-1}');
}

getVendasByIdVendedor(idVendedor:any){
  return this.http.get(this.apiVendas+'&method=GET&filtro={"idVendedor":"'+idVendedor+'"}&sort={"data":-1}');
}

patchVenda(id:any,dados:any){
  let body = JSON.stringify(dados);
  return this.http.get(this.apiVendas+'&method=PATCH&id='+id+'&body='+body);
}

cadastrarVenda(dados:any){
  let body = JSON.stringify(dados);
  return this.http.get(this.apiVendas+'&method=POST&body='+body);
}

}