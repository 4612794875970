import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { DataBaseService } from 'src/app/provider.service';
//import html2pdf from 'html2pdf.js';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-vendas',
  templateUrl: './vendas.component.html',
  styleUrls: ['./vendas.component.scss']
})

export class VendasComponent implements OnInit {
  vendas:any = [];
  idVenda:any = [];
  vendaSelecionada:any = {};
  municipio:any = "";
  locais:any = "";
  funeralExtra:number = 0;
  funeralComplementar:number = 0;
  convenioSaude:number = 0;
  telemedicina:number = 0;
  faturamento:number = 0;
  qtdeSeguro5:number = 0;
  qtdeSeguro10:number = 0;
  qtdeSeguro15:number = 0;
  qtdeSeguro20:number = 0;
  qtdeSeguro25:number = 0;
  qtdeSeguro35:number = 0;
  qtdeSeguro50:number = 0;
  qtdeFuneralExtra:number = 0;
  qtdefuneralComplementar:number = 0;
  qtdeConvenioSaude:number = 0;
  qtdeTelemedicina:number = 0;
  funeralExtraMigracao:number = 0;
  funeralComplementarMigracao:number = 0;
  convenioSaudeMigracao:number = 0;
  telemedicinaMigracao:number = 0;
  faturamentoMigracao:number = 0;
  qtdeSeguro5Migracao:number = 0;
  qtdeSeguro10Migracao:number = 0;
  qtdeSeguro15Migracao:number = 0;
  qtdeSeguro20Migracao:number = 0;
  qtdeSeguro25Migracao:number = 0;
  qtdeSeguro35Migracao:number = 0;
  qtdeSeguro50Migracao:number = 0;
  qtdeFuneralExtraMigracao:number = 0;
  qtdefuneralComplementarMigracao:number = 0;
  qtdeConvenioSaudeMigracao:number = 0;
  qtdeTelemedicinaMigracao:number = 0;
  qtdeFiliacoes:number = 0;
  nomeVendedor:any = "";
  filiacoes:any = "";
  dataHoje:any = "";

  constructor(private router:Router, private db:DataBaseService, public app:AppComponent) { }

  ngOnInit(): void {
    this.nomeVendedor = localStorage.getItem('usuarioVendas');
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.getVendas();
  }

  getVendas(){
    let id:any = localStorage.getItem('idUserVendas')
    this.db.getVendasByIdVendedor(id).subscribe((data:any) => {
      this.vendas = data;
    })
  }

  openVenda(){
    let mod:any = document.getElementById('modalVenda');
    mod.style.display = "block";
  }

  closeVenda(){
    let mod:any = document.getElementById('modalVenda');
    mod.style.display = "none";
    this.municipio = "";
    this.locais = "";
    this.qtdeSeguro5 = 0;
    this.qtdeSeguro10 = 0;
    this.qtdeSeguro15 = 0;
    this.qtdeSeguro20 = 0;
    this.qtdeSeguro25 = 0;
    this.qtdeSeguro35 = 0;
    this.qtdeSeguro50 = 0;
    this.qtdeFuneralExtra = 0;
    this.qtdefuneralComplementar = 0;
    this.qtdeConvenioSaude = 0;
    this.qtdeTelemedicina = 0;
    this.qtdeFiliacoes = 0;
    this.funeralExtra = 0;
    this.funeralComplementar = 0;
    this.convenioSaude = 0;
    this.telemedicina = 0;
    this.qtdeSeguro5Migracao = 0;
    this.qtdeSeguro10Migracao = 0;
    this.qtdeSeguro15Migracao = 0;
    this.qtdeSeguro20Migracao = 0;
    this.qtdeSeguro25Migracao = 0;
    this.qtdeSeguro35Migracao = 0;
    this.qtdeSeguro50Migracao = 0;
    this.qtdeFuneralExtraMigracao = 0;
    this.qtdefuneralComplementarMigracao = 0;
    this.qtdeConvenioSaudeMigracao = 0;
    this.qtdeTelemedicinaMigracao = 0;
    this.funeralExtraMigracao = 0;
    this.funeralComplementarMigracao = 0;
    this.convenioSaudeMigracao = 0;
    this.telemedicinaMigracao = 0;
    this.faturamento = 0;
    this.filiacoes = "";
  }

  openVendaEditar(venda:any){
    this.idVenda = venda._id;
    this.vendaSelecionada = venda;
    let mod:any = document.getElementById('modalVendaEditar');
    mod.style.display = "block";
  }

  closeVendaEditar(){
    let mod:any = document.getElementById('modalVendaEditar');
    mod.style.display = "none";
    this.vendaSelecionada = {};
    this.idVenda = "";
  }

  cadastrarVenda(){
    let idVendedor:any = localStorage.getItem('idUserVendas');
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let obj:any = {
      idVendedor: idVendedor,
      municipio: this.municipio,
      locais: this.locais,
      qtdeSeguro5: this.qtdeSeguro5,
      qtdeSeguro10:this.qtdeSeguro10,
      qtdeSeguro15:this.qtdeSeguro15,
      qtdeSeguro20:this.qtdeSeguro20,
      qtdeSeguro25:this.qtdeSeguro25,
      qtdeSeguro35:this.qtdeSeguro35,
      qtdeSeguro50:this.qtdeSeguro50,
      qtdeFuneralExtra:this.qtdeFuneralExtra,
      qtdefuneralComplementar:this.qtdefuneralComplementar,
      qtdeConvenioSaude:this.qtdeConvenioSaude,
      qtdeTelemedicina:this.qtdeTelemedicina,
      qtdeFiliacoes : this.qtdeFiliacoes,
      funeralExtra: this.funeralExtra,
      funeralComplementar : this.funeralComplementar,
      convenioSaude : this.convenioSaude,
      telemedicina : this.telemedicina,
      qtdeSeguro5Migracao: this.qtdeSeguro5Migracao,
      qtdeSeguro10Migracao:this.qtdeSeguro10Migracao,
      qtdeSeguro15Migracao:this.qtdeSeguro15Migracao,
      qtdeSeguro20Migracao:this.qtdeSeguro20Migracao,
      qtdeSeguro25Migracao:this.qtdeSeguro25Migracao,
      qtdeSeguro35Migracao:this.qtdeSeguro35Migracao,
      qtdeSeguro50Migracao:this.qtdeSeguro50Migracao,
      qtdeFuneralExtraMigracao:this.qtdeFuneralExtraMigracao,
      qtdefuneralComplementarMigracao:this.qtdefuneralComplementarMigracao,
      qtdeConvenioSaudeMigracao:this.qtdeConvenioSaudeMigracao,
      qtdeTelemedicinaMigracao:this.qtdeTelemedicinaMigracao,
      funeralExtraMigracao: this.funeralExtraMigracao,
      funeralComplementarMigracao : this.funeralComplementarMigracao,
      convenioSaudeMigracao : this.convenioSaudeMigracao,
      telemedicinaMigracao : this.telemedicinaMigracao,
      faturamento : this.faturamento,
      filiacoes: this.filiacoes,
      situacao : "NÃO ENVIADO",
      vendedor : this.nomeVendedor,
      data: this.dataHoje,
      dataPDF : "",
    }
    this.db.cadastrarVenda(obj).subscribe((res:any) => {
      console.log(res);
      this.closeVenda();
      this.getVendas();
    }, (err:any) => {
      console.log(err);
      alert("Erro ao cadastrar! Tente Novamente");
    })
  }

  editarVenda(){
    delete this.vendaSelecionada._id;
    this.db.patchVenda(this.idVenda,this.vendaSelecionada).subscribe((res:any) => {
      console.log(res);
      this.closeVendaEditar();
      this.getVendas();
    }, (err:any) => {
      console.log(err)
    })
  }

  addSeguro5(nr:number){
    if(nr == 0){
      this.qtdeSeguro5 += 1;
      this.faturamento += 3.86;
    }else{
      this.vendaSelecionada.qtdeSeguro5 += 1;
      this.vendaSelecionada.faturamento += 3.86;
    }
  }

  rmSeguro5(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro5 > 0){
        this.qtdeSeguro5 -= 1;
        this.faturamento -= 3.86;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro5 > 0){
        this.vendaSelecionada.qtdeSeguro5 -= 1;
        this.vendaSelecionada.faturamento -= 3.86;
      }
    }
  }

  addSeguro5Migracao(nr:number){
    if(nr == 0){
      this.qtdeSeguro5Migracao += 1;
      this.faturamento += 3.86;
    }else{
      this.vendaSelecionada.qtdeSeguro5Migracao += 1;
      this.vendaSelecionada.faturamento += 3.86;
    }
  }

  rmSeguro5Migracao(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro5Migracao > 0){
        this.qtdeSeguro5Migracao -= 1;
        this.faturamento -= 3.86;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro5Migracao > 0){
        this.vendaSelecionada.qtdeSeguro5Migracao -= 1;
        this.vendaSelecionada.faturamento -= 3.86;
      }
    }
  }

  addSeguro10(nr:number){
    if(nr == 0){
      this.qtdeSeguro10 += 1;
      this.faturamento += 13.34;
    }else{
      this.vendaSelecionada.qtdeSeguro10 += 1;
      this.vendaSelecionada.faturamento += 13.34;
    }
  }

  rmSeguro10(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro10 > 0){
        this.qtdeSeguro10 -= 1;
        this.faturamento -= 13.34;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro10 > 0){
        this.vendaSelecionada.qtdeSeguro10 -= 1;
        this.vendaSelecionada.faturamento -= 13.34;
      }
    }
  }

  addSeguro10Migracao(nr:number){
    if(nr == 0){
      this.qtdeSeguro10Migracao += 1;
      this.faturamento += 13.34;
    }else{
      this.vendaSelecionada.qtdeSeguro10Migracao += 1;
      this.vendaSelecionada.faturamento += 13.34;
    }
  }

  rmSeguro10Migracao(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro10Migracao > 0){
        this.qtdeSeguro10Migracao -= 1;
        this.faturamento -= 13.34;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro10Migracao > 0){
        this.vendaSelecionada.qtdeSeguro10Migracao -= 1;
        this.vendaSelecionada.faturamento -= 13.34;
      }
    }
  }

  addSeguro15(nr:number){
    if(nr == 0){
      this.qtdeSeguro15 += 1;
      this.faturamento += 20.01;
    }else{
      this.vendaSelecionada.qtdeSeguro15 += 1;
      this.vendaSelecionada.faturamento += 20.01;
    }
  }

  rmSeguro15(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro15 > 0){
        this.qtdeSeguro15 -= 1;
        this.faturamento -= 20.01;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro15 > 0){
        this.vendaSelecionada.qtdeSeguro15 -= 1;
        this.vendaSelecionada.faturamento -= 20.01;
      }
    }
  }

  addSeguro15Migracao(nr:number){
    if(nr == 0){
      this.qtdeSeguro15Migracao += 1;
      this.faturamento += 20.01;
    }else{
      this.vendaSelecionada.qtdeSeguro15Migracao += 1;
      this.vendaSelecionada.faturamento += 20.01;
    }
  }

  rmSeguro15Migracao(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro15Migracao > 0){
        this.qtdeSeguro15Migracao -= 1;
        this.faturamento -= 20.01;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro15Migracao > 0){
        this.vendaSelecionada.qtdeSeguro15Migracao -= 1;
        this.vendaSelecionada.faturamento -= 20.01;
      }
    }
  }

  addSeguro20(nr:number){
    if(nr == 0){
      this.qtdeSeguro20 += 1;
      this.faturamento += 26.71;
    }else{
      this.vendaSelecionada.qtdeSeguro20 += 1;
      this.vendaSelecionada.faturamento += 26.71;
    }
  }

  rmSeguro20(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro20 > 0){
        this.qtdeSeguro20 -= 1;
        this.faturamento -= 26.71;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro20 > 0){
        this.vendaSelecionada.qtdeSeguro20 -= 1;
        this.vendaSelecionada.faturamento -= 26.71;
      }
    }
  }

  addSeguro20Migracao(nr:number){
    if(nr == 0){
      this.qtdeSeguro20Migracao += 1;
      this.faturamento += 26.71;
    }else{
      this.vendaSelecionada.qtdeSeguro20Migracao += 1;
      this.vendaSelecionada.faturamento += 26.71;
    }
  }

  rmSeguro20Migracao(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro20Migracao > 0){
        this.qtdeSeguro20Migracao -= 1;
        this.faturamento -= 26.71;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro20Migracao > 0){
        this.vendaSelecionada.qtdeSeguro20Migracao -= 1;
        this.vendaSelecionada.faturamento -= 26.71;
      }
    }
  }

  addSeguro25(nr:number){
    if(nr == 0){
      this.qtdeSeguro25 += 1;
      this.faturamento += 33.39;
    }else{
      this.vendaSelecionada.qtdeSeguro25 += 1;
      this.vendaSelecionada.faturamento += 33.39;
    }
  }

  rmSeguro25(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro25 > 0){
        this.qtdeSeguro25 -= 1;
        this.faturamento -= 33.39;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro25 > 0){
        this.vendaSelecionada.qtdeSeguro25 -= 1;
        this.vendaSelecionada.faturamento -= 33.39;
      }
    }
  }
  
  addSeguro25Migracao(nr:number){
    if(nr == 0){
      this.qtdeSeguro25Migracao += 1;
      this.faturamento += 33.39;
    }else{
      this.vendaSelecionada.qtdeSeguro25Migracao += 1;
      this.vendaSelecionada.faturamento += 33.39;
    }
  }

  rmSeguro25Migracao(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro25Migracao > 0){
        this.qtdeSeguro25Migracao -= 1;
        this.faturamento -= 33.39;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro25Migracao > 0){
        this.vendaSelecionada.qtdeSeguro25Migracao -= 1;
        this.vendaSelecionada.faturamento -= 33.39;
      }
    }
  }

  addSeguro35(nr:number){
    if(nr == 0){
      this.qtdeSeguro35 += 1;
      this.faturamento += 45.08;
    }else{
      this.vendaSelecionada.qtdeSeguro35 += 1;
      this.vendaSelecionada.faturamento += 45.08;
    }
  }

  rmSeguro35(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro35 > 0){
        this.qtdeSeguro35 -= 1;
        this.faturamento -= 45.08;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro35 > 0){
        this.vendaSelecionada.qtdeSeguro35 -= 1;
        this.vendaSelecionada.faturamento -= 45.08;
      }
    }
  }

  addSeguro35Migracao(nr:number){
    if(nr == 0){
      this.qtdeSeguro35Migracao += 1;
      this.faturamento += 45.08;
    }else{
      this.vendaSelecionada.qtdeSeguro35Migracao += 1;
      this.vendaSelecionada.faturamento += 45.08;
    }
  }

  rmSeguro35Migracao(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro35Migracao > 0){
        this.qtdeSeguro35Migracao -= 1;
        this.faturamento -= 45.08;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro35Migracao > 0){
        this.vendaSelecionada.qtdeSeguro35Migracao -= 1;
        this.vendaSelecionada.faturamento -= 45.08;
      }
    }
  }

  addSeguro50(nr:number){
    if(nr == 0){
      this.qtdeSeguro50 += 1;
      this.faturamento += 66.78;
    }else{
      this.vendaSelecionada.qtdeSeguro50 += 1;
      this.vendaSelecionada.faturamento += 66.78;
    }
  }

  rmSeguro50(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro50 > 0){
        this.qtdeSeguro50 -= 1;
        this.faturamento -= 66.78;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro50 > 0){
        this.vendaSelecionada.qtdeSeguro50 -= 1;
        this.vendaSelecionada.faturamento -= 66.78;
      }
    }
  }
  
  addSeguro50Migracao(nr:number){
    if(nr == 0){
      this.qtdeSeguro50Migracao += 1;
      this.faturamento += 66.78;
    }else{
      this.vendaSelecionada.qtdeSeguro50Migracao += 1;
      this.vendaSelecionada.faturamento += 66.78;
    }
  }

  rmSeguro50Migracao(nr:number){
    if(nr == 0){
      if(this.qtdeSeguro50Migracao > 0){
        this.qtdeSeguro50Migracao -= 1;
        this.faturamento -= 66.78;
      }
    }else{
      if(this.vendaSelecionada.qtdeSeguro50Migracao > 0){
        this.vendaSelecionada.qtdeSeguro50Migracao -= 1;
        this.vendaSelecionada.faturamento -= 66.78;
      }
    }
  }

  addFuneralExtra(nr:number){
    if(nr == 0){
      this.qtdeFuneralExtra += 1;
      this.faturamento += 15;
    }else{
      this.vendaSelecionada.qtdeFuneralExtra += 1;
      this.vendaSelecionada.faturamento += 15;
    }
  }

  rmFuneralExtra(nr:number){
    if(nr == 0){
      if(this.qtdeFuneralExtra > 0){
        this.qtdeFuneralExtra -= 1;
        this.faturamento -= 15;
      }
    }else{
      if(this.vendaSelecionada.qtdeFuneralExtra > 0){
        this.vendaSelecionada.qtdeFuneralExtra -= 1;
        this.vendaSelecionada.faturamento -= 15;
      }
    }
  }
  
  addFuneralExtraMigracao(nr:number){
    if(nr == 0){
      this.qtdeFuneralExtraMigracao += 1;
      this.faturamento += 15;
    }else{
      this.vendaSelecionada.qtdeFuneralExtraMigracao += 1;
      this.vendaSelecionada.faturamento += 15;
    }
  }

  rmFuneralExtraMigracao(nr:number){
    if(nr == 0){
      if(this.qtdeFuneralExtraMigracao > 0){
        this.qtdeFuneralExtraMigracao -= 1;
        this.faturamento -= 15;
      }
    }else{
      if(this.vendaSelecionada.qtdeFuneralExtraMigracao > 0){
        this.vendaSelecionada.qtdeFuneralExtraMigracao -= 1;
        this.vendaSelecionada.faturamento -= 15;
      }
    }
  }

  addFuneralComplementar(nr:number){
    if(nr == 0){
      this.qtdefuneralComplementar += 1;
      this.faturamento += 45;
    }else{
      this.vendaSelecionada.qtdefuneralComplementar += 1;
      this.vendaSelecionada.faturamento += 45;
    }
  }

  rmFuneralComplementar(nr:number){
    if(nr == 0){
      if(this.qtdefuneralComplementar > 0){
        this.qtdefuneralComplementar -= 1;
        this.faturamento -= 45;
      }
    }else{
      if(this.vendaSelecionada.qtdefuneralComplementar > 0){
        this.vendaSelecionada.qtdefuneralComplementar -= 1;
        this.vendaSelecionada.faturamento -= 45;
      }
    }
  }
  
  addFuneralComplementarMigracao(nr:number){
    if(nr == 0){
      this.qtdefuneralComplementarMigracao += 1;
      this.faturamento += 45;
    }else{
      this.vendaSelecionada.qtdefuneralComplementarMigracao += 1;
      this.vendaSelecionada.faturamento += 45;
    }
  }

  rmFuneralComplementarMigracao(nr:number){
    if(nr == 0){
      if(this.qtdefuneralComplementarMigracao > 0){
        this.qtdefuneralComplementarMigracao -= 1;
        this.faturamento -= 45;
      }
    }else{
      if(this.vendaSelecionada.qtdefuneralComplementarMigracao > 0){
        this.vendaSelecionada.qtdefuneralComplementarMigracao -= 1;
        this.vendaSelecionada.faturamento -= 45;
      }
    }
  }

  addConvenioSaude(nr:number){
    if(nr == 0){
      this.qtdeConvenioSaude += 1;
      this.faturamento += 19.90;
    }else{
      this.vendaSelecionada.qtdeConvenioSaude += 1;
      this.vendaSelecionada.faturamento += 19.90;
    }
  }

  rmConvenioSaude(nr:number){
    if(nr == 0){
      if(this.qtdeConvenioSaude > 0){
        this.qtdeConvenioSaude -= 1;
        this.faturamento -= 19.90;
      }
    }else{
      if(this.vendaSelecionada.qtdeConvenioSaude > 0){
        this.vendaSelecionada.qtdeConvenioSaude -= 1;
        this.vendaSelecionada.faturamento -= 19.90;
      }
    }
  }

  addConvenioSaudeMigracao(nr:number){
    if(nr == 0){
      this.qtdeConvenioSaudeMigracao += 1;
      this.faturamento += 19.90;
    }else{
      this.vendaSelecionada.qtdeConvenioSaudeMigracao += 1;
      this.vendaSelecionada.faturamento += 19.90;
    }
  }

  rmConvenioSaudeMigracao(nr:number){
    if(nr == 0){
      if(this.qtdeConvenioSaudeMigracao > 0){
        this.qtdeConvenioSaudeMigracao -= 1;
        this.faturamento -= 19.90;
      }
    }else{
      if(this.vendaSelecionada.qtdeConvenioSaudeMigracao > 0){
        this.vendaSelecionada.qtdeConvenioSaudeMigracao -= 1;
        this.vendaSelecionada.faturamento -= 19.90;
      }
    }
  }

  addTelemedicina(nr:number){
    if(nr == 0){
      this.qtdeTelemedicina += 1;
      this.faturamento += 17.90;
    }else{
      this.vendaSelecionada.qtdeTelemedicina += 1;
      this.vendaSelecionada.faturamento += 17.90;
    }
  }

  rmTelemedicina(nr:number){
    if(nr == 0){
      if(this.qtdeTelemedicina > 0){
        this.qtdeTelemedicina -= 1;
        this.faturamento -= 17.90;
      }
    }else{
      if(this.vendaSelecionada.qtdeTelemedicina > 0){
        this.vendaSelecionada.qtdeTelemedicina -= 1;
        this.vendaSelecionada.faturamento -= 17.90;
      }
    }
  }
  
  addTelemedicinaMigracao(nr:number){
    if(nr == 0){
      this.qtdeTelemedicinaMigracao += 1;
      this.faturamento += 17.90;
    }else{
      this.vendaSelecionada.qtdeTelemedicinaMigracao += 1;
      this.vendaSelecionada.faturamento += 17.90;
    }
  }

  rmTelemedicinaMigracao(nr:number){
    if(nr == 0){
      if(this.qtdeTelemedicinaMigracao > 0){
        this.qtdeTelemedicinaMigracao -= 1;
        this.faturamento -= 17.90;
      }
    }else{
      if(this.vendaSelecionada.qtdeTelemedicinaMigracao > 0){
        this.vendaSelecionada.qtdeTelemedicinaMigracao -= 1;
        this.vendaSelecionada.faturamento -= 17.90;
      }
    }
  }

  addFiliacoes(nr:number){
    if(nr == 0){
      this.qtdeFiliacoes += 1;
    }else{
      this.vendaSelecionada.qtdeFiliacoes += 1;
    }
  }

  rmFiliacoes(nr:number){
    if(nr == 0){
      if(this.qtdeFiliacoes > 0){
        this.qtdeFiliacoes -= 1;
      }
    }else{
      if(this.vendaSelecionada.qtdeFiliacoes > 0){
        this.vendaSelecionada.qtdeFiliacoes -= 1;
      }
    }
  }

  backToDash(){
    this.router.navigate(['/inicio']);
  }

  gerarPDF(venda:any){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.vendaSelecionada = venda;
    this.idVenda = venda._id;
    if(venda.situacao == "NÃO ENVIADO"){
      if(window.confirm("Ao Gerar o PDF não será mais permitido ALTERAÇÕES, deseja continuar?")){
      delete this.vendaSelecionada._id;
      this.vendaSelecionada.dataPDF = this.dataHoje;
      this.vendaSelecionada.situacao = "ENVIADO";
      this.db.patchVenda(this.idVenda,this.vendaSelecionada).subscribe((res:any) => {
        console.log(res);
        const element: HTMLElement | null = document.getElementById('imprimir');
        if (!element) {
          console.error('Elemento não encontrado!');
          return;
        }
        const options: any = {
          margin: [1, 1, 1, 1],
          filename: 'vendas.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
        };
        setTimeout(() => {
          html2pdf()
            .from(element)
            .set(options)
            .save().then(() => {this.getVendas()})
        }, 500);
      }, (err:any) => {
        console.log(err)
      })
    }
    }else{
      const element: HTMLElement | null = document.getElementById('imprimir');
      if (!element) {
        console.error('Elemento não encontrado!');
        return;
      }
      const options: any = {
        margin: [1, 1, 1, 1],
        filename: 'vendas.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
      };
      setTimeout(() => {
        html2pdf()
          .from(element)
          .set(options)
          .save();
      }, 500);
    }
    
  }
  
  

}
